<template>
  <div class="tw-element-endingprogress__holder">
    <div class="tw-element-endingprogress">
      <div class="tw-element-endingprogress-value" :style="textStyle">
        {{ percentage }}%
      </div>
      <svg viewBox="0 0 36 36" class="tw-element-endingprogress-bar">
        <path
          class="tw-element-endingprogress-bar-bg"
          d="M18 2.0845
            a 15.9155 15.9155 0 0 1 0 31.831
            a 15.9155 15.9155 0 0 1 0 -31.831"
        />
        <path
          class="tw-element-endingprogress-bar-track"
          :stroke-dasharray="`${percentage}, 100`"
          d="M18 2.0845
            a 15.9155 15.9155 0 0 1 0 31.831
            a 15.9155 15.9155 0 0 1 0 -31.831"
        />
      </svg>
    </div>
    <ProgressSteps
      :config="config"
      :page-options="pageOptions"
      :current-percentage="percentage"
      :steps="steps"
    />
  </div>
</template>

<script lang="ts" setup>
import { type PropType, computed, ref, onMounted, toRef } from 'vue';
import type { Element as ElementType, PageOptions } from '@shared/types/model';
import type { ElementOptions } from '@shared/types/options';
import ProgressSteps from './components/progress-steps/index.vue';
import { useSpacing } from '@shared/composable/useSpacing';

const DEFAULT_WAIT_TIME = 5;
const DEFAULT_PERCENTAGE = 55;

const props = defineProps({
  isEditorMode: { type: Boolean, default: true },
  config: { type: Object as PropType<ElementOptions['endingprogress']>, default: () => ({}) },
  pageOptions: { type: Object as PropType<PageOptions>, default: () => ({}) },
  element: { type: Object as PropType<ElementType<'endingprogress'>>, default: () => ({}) }
});

const element = toRef(props, 'element');
const { padding, margin } = useSpacing(element);

const percentage = ref(0);
const waitTime = ref(props.isEditorMode ? DEFAULT_WAIT_TIME : props.config?.waitTime || DEFAULT_WAIT_TIME);
const endingProgressStyle = computed(() => {
  return {
    bgColor: props.config.bar?.bgColor,
    trackColor: props.config.bar?.trackColor || props.pageOptions.colors.theme[0],
    fontFamily: props.pageOptions.fontFamily
  };
});

const values = computed(() => JSON.parse(props.element.values || '{}'));
const steps = computed(() =>
  (values.value?.steps || [])?.slice(0, props.config.steps?.count || props.config.steps?.maxStepsCount)
);

const textStyle = computed(() => {
  return {
    color: props.element.options.steps?.text.done || props.pageOptions.colors.text[0],
    fontFamily: props.element.options.steps?.fontFamily || props.pageOptions.fontFamily,
  }
})

const startTimer = () => {
  const timer = setInterval(() => {
    if (percentage.value < 100) {
      percentage.value++;
    } else {
      clearInterval(timer);
    }
  }, waitTime.value * 10);
};

onMounted(() => {
  if (props.isEditorMode) {
    percentage.value = DEFAULT_PERCENTAGE;
  } else {
    startTimer();
  }
});
</script>

<style lang="postcss" scoped>
.tw-element-endingprogress {
  --endingprogress-barBgColor: v-bind('endingProgressStyle.bgColor');
  --endingprogress-barTrackColor: v-bind('endingProgressStyle.trackColor');
  --endingprogress-fontFamily: v-bind('endingProgressStyle.fontFamily');

  @apply relative !mx-auto h-128 w-128;
  font-family: var(--endingprogress-fontFamily);

  &__holder {
    /* PADDING */
    --endingprogress-desktop-padding-top: v-bind('padding?.desktop?.top');
    --endingprogress-desktop-padding-left: v-bind('padding?.desktop?.left');
    --endingprogress-desktop-padding-bottom: v-bind('padding?.desktop?.bottom');
    --endingprogress-desktop-padding-right: v-bind('padding?.desktop?.right');

    --endingprogress-tablet-padding-top: v-bind('padding?.tablet?.top');
    --endingprogress-tablet-padding-left: v-bind('padding?.tablet?.left');
    --endingprogress-tablet-padding-bottom: v-bind('padding?.tablet?.bottom');
    --endingprogress-tablet-padding-right: v-bind('padding?.tablet?.right');

    --endingprogress-mobile-padding-top: v-bind('padding?.mobile?.top');
    --endingprogress-mobile-padding-left: v-bind('padding?.mobile?.left');
    --endingprogress-mobile-padding-bottom: v-bind('padding?.mobile?.bottom');
    --endingprogress-mobile-padding-right: v-bind('padding?.mobile?.right');
    
    /* MARGIN */
    --endingprogress-desktop-margin-top: v-bind('margin?.desktop?.top');
    --endingprogress-desktop-margin-left: v-bind('margin?.desktop?.left');
    --endingprogress-desktop-margin-bottom: v-bind('margin?.desktop?.bottom');
    --endingprogress-desktop-margin-right: v-bind('margin?.desktop?.right');

    --endingprogress-tablet-margin-top: v-bind('margin?.tablet?.top');
    --endingprogress-tablet-margin-left: v-bind('margin?.tablet?.left');
    --endingprogress-tablet-margin-bottom: v-bind('margin?.tablet?.bottom');
    --endingprogress-tablet-margin-right: v-bind('margin?.tablet?.right');

    --endingprogress-mobile-margin-top: v-bind('margin?.mobile?.top');
    --endingprogress-mobile-margin-left: v-bind('margin?.mobile?.left');
    --endingprogress-mobile-margin-bottom: v-bind('margin?.mobile?.bottom');
    --endingprogress-mobile-margin-right: v-bind('margin?.mobile?.right');

    @apply flex flex-col;
    padding-top: var(--endingprogress-mobile-padding-top, 0px);
    padding-left: var(--endingprogress-mobile-padding-left, 0px);
    padding-bottom: var(--endingprogress-mobile-padding-bottom, 0px);
    padding-right: var(--endingprogress-mobile-padding-right, 0px);
    
    margin-top: var(--endingprogress-mobile-margin-top, 0px);
    margin-left: var(--endingprogress-mobile-margin-left, 0px);
    margin-bottom: var(--endingprogress-mobile-margin-bottom, 0px);
    margin-right: var(--endingprogress-mobile-margin-right, 0px);
  }


  &-bar{
    display: block;
    width: 128px;
    height: 128px;

    &-bg, &-track {
      @apply fill-none stroke-[2px];
    }

    &-bg {
      fill: none;
      stroke: var(--endingprogress-barBgColor, #e5e5ea);
    }
    &-track {
      animation: progress 1s ease-out forwards;
      stroke: var(--endingprogress-barTrackColor, #56c4d6);
    }
  }

  &-value {
    @apply absolute h-full w-full flex items-center justify-center text-[20px] indent-4 font-semibold text-neutral-900;
  }

  @keyframes progress {
    0% { stroke-dasharray: 0 100; }
  }
}

.desktop {
  .tw-element-endingprogress__holder {
    padding-top: var(--endingprogress-desktop-padding-top, 0px);
    padding-left: var(--endingprogress-desktop-padding-left, 0px);
    padding-bottom: var(--endingprogress-desktop-padding-bottom, 0px);
    padding-right: var(--endingprogress-desktop-padding-right, 0px);

    margin-top: var(--endingprogress-desktop-margin-top, 0px);
    margin-left: var(--endingprogress-desktop-margin-left, 0px);
    margin-bottom: var(--endingprogress-desktop-margin-bottom, 0px);
    margin-right: var(--endingprogress-desktop-margin-right, 0px);
  }
}

.tablet {
  .tw-element-endingprogress__holder {
    padding-top: var(--endingprogress-tablet-padding-top, 0px);
    padding-left: var(--endingprogress-tablet-padding-left, 0px);
    padding-bottom: var(--endingprogress-tablet-padding-bottom, 0px);
    padding-right: var(--endingprogress-tablet-padding-right, 0px);

    margin-top: var(--endingprogress-tablet-margin-top, 0px);
    margin-left: var(--endingprogress-tablet-margin-left, 0px);
    margin-bottom: var(--endingprogress-tablet-margin-bottom, 0px);
    margin-right: var(--endingprogress-tablet-margin-right, 0px);
  }
}

.mobile {
  .tw-element-endingprogress__holder {
    padding-top: var(--endingprogress-mobile-padding-top, 0px);
    padding-left: var(--endingprogress-mobile-padding-left, 0px);
    padding-bottom: var(--endingprogress-mobile-padding-bottom, 0px);
    padding-right: var(--endingprogress-mobile-padding-right, 0px);

    margin-top: var(--endingprogress-mobile-margin-top, 0px);
    margin-left: var(--endingprogress-mobile-margin-left, 0px);
    margin-bottom: var(--endingprogress-mobile-margin-bottom, 0px);
    margin-right: var(--endingprogress-mobile-margin-right, 0px);
  }
}

.tw-element-endingprogress__holder {
  @media (min-width: 768px) {
    padding-top: var(--endingprogress-tablet-padding-top, 0px);
    padding-left: var(--endingprogress-tablet-padding-left, 0px);
    padding-bottom: var(--endingprogress-tablet-padding-bottom, 0px);
    padding-right: var(--endingprogress-tablet-padding-right, 0px);
    
    margin-top: var(--endingprogress-tablet-margin-top, 0px);
    margin-left: var(--endingprogress-tablet-margin-left, 0px);
    margin-bottom: var(--endingprogress-tablet-margin-bottom, 0px);
    margin-right: var(--endingprogress-tablet-margin-right, 0px);
  }

  @media (min-width: 1024px) {
    padding-top: var(--endingprogress-desktop-padding-top, 0px);
    padding-left: var(--endingprogress-desktop-padding-left, 0px);
    padding-bottom: var(--endingprogress-desktop-padding-bottom, 0px);
    padding-right: var(--endingprogress-desktop-padding-right, 0px);
    
    margin-top: var(--endingprogress-desktop-margin-top, 0px);
    margin-left: var(--endingprogress-desktop-margin-left, 0px);
    margin-bottom: var(--endingprogress-desktop-margin-bottom, 0px);
    margin-right: var(--endingprogress-desktop-margin-right, 0px);
  }
}
</style>
